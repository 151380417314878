import { defineStore } from 'pinia';

export const useDataImport = defineStore('dataImport', {
  state: () => ({
    importJobIds: [],
    importJobStates: {},
    importJobCompletedAt: {},
  }),
  actions: {
    async addImportJobId(jobId: string) {
      if (!this.importJobIds.includes(jobId)) {
        this.importJobIds.push(jobId);

        this.fetchImportJobStates();
      }
    },
    async fetchImportJobStates() {
      this.importJobIds.forEach((jobId) => {
        if (this.importJobStates[jobId]?.status === 'completed') {
          setTimeout(() => {
            this.importJobStates[jobId] = undefined;
          }, 100000);
          return;
        }

        $fetch('/api/v1/data-flow/import-status/' + jobId).then((response) => {
          this.importJobStates[jobId] = response;

          if (response.status === 'completed' && !this.importJobCompletedAt[jobId]) {
            this.importJobCompletedAt[jobId] = new Date();
          }
        });
      });
    }
  },
  getters: {
    getImportJobIds: (state) => {
      return state.importJobIds;
    },
    getImportJobStates: (state) => {
      // return as array where completed in the last 15 minutes are removed
      return Object.keys(state.importJobStates).map((key) => state.importJobStates[key]).filter((job) => {
        if (job?.status === 'completed') {
          return state.importJobCompletedAt[job.id] > new Date(new Date().getTime() - 15 * 60000);
        }
        return true;
      });
    },
  },
  persist: true,
});
